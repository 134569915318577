import React from 'react'
import Meta from 'components/meta'
import Layout from 'components/layout'
import logo from '../img/instar_logo_gradient_white_text.svg'
import { Link } from 'gatsby'

class NotFound extends React.Component {
  render() {
    return (
      <div className="particles-container">
        <div className="not-found">
          <Link to="/">
            <img src={logo} alt="logo" width="200px" />
            <h2>
              This place is not the place you're looking for, you should visit
              our home page...
            </h2>
          </Link>
        </div>
      </div>
    )
  }
}

export default NotFound
